import './App.css';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';

const HomePage = React.lazy(() => import ('./page/HomePage'));
const HomePageTuRuou = React.lazy(() => import ('./page/HomePageTuRuou'));
const HomePageTuAo = React.lazy(() => import ('./page/HomePageTuAo'));
const HomePageTuBep = React.lazy(() => import ('./page/HomePageTuBep'));
const LetterFromFounder = React.lazy(() => import ('./page/LetterFromFounder'));
const Product = React.lazy(() => import ('./page/Product'));
const TuRuouCanhKinh = React.lazy(() => import ('./page/TuRuouCanhKinh'));
const TuRuouFullKinh = React.lazy(() => import ('./page/TuRuouFullKinh'));
const PhuKienTuBep = React.lazy(() => import ('./page/PhuKienTuBep'));
const PhuKienPhongThayDo = React.lazy(() => import ('./page/PhuKienPhongThayDo'));
const PhuKienTuRuou = React.lazy(() => import ('./page/PhuKienTuRuou'));
const TuAoCanhKinh = React.lazy(() => import ('./page/TuAoCanhKinh'));
const TuAoFullKinh = React.lazy(() => import ('./page/TuAoFullKinh'));
const HeTuBepSmart = React.lazy(() => import ('./page/HeTuBepSmart'));
const SmartKitchenPro = React.lazy(() => import ('./page/SmartKitchenPro'));
const SmartSpaceElegance = React.lazy(() => import ('./page/SmartSpaceElegance'));
const HeTuBepLuxury = React.lazy(() => import ('./page/HeTuBepLuxury'));
const LuxuryKitchenElite = React.lazy(() => import ('./page/LuxuryKitchenElite'));
const LuxurySpaceGrandeur = React.lazy(() => import ('./page/LuxurySpaceGrandeur'));
const Project = React.lazy(() => import ('./page/Project'));
const Project1 = React.lazy(() => import ('./page/Project1'));
const Project2 = React.lazy(() => import ('./page/Project2'));
const Project3 = React.lazy(() => import ('./page/Project3'));
const Project4 = React.lazy(() => import ('./page/Project4'));
const Showroom = React.lazy(() => import ('./page/Showroom'));
const Contact = React.lazy(() => import ('./page/Contact'));
const Solution = React.lazy(() => import ('./page/Solution'));

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={
        <div className="spinner flex items-center justify-center h-screen bg-gray-100">
          <ClipLoader color="#007bff" size={50} />
        </div>
      }>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/homepage/tu-ruou" element={<HomePageTuRuou />} />
          <Route path="/homepage/tu-ao" element={<HomePageTuAo />} />
          <Route path="/homepage/tu-bep" element={<HomePageTuBep />} />
          <Route path="/letter-from-founder" element={<LetterFromFounder />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product/tu-ruou/tu-ruou-canh-kinh" element={<TuRuouCanhKinh />} />
          <Route path="/product/tu-ruou/tu-ruou-full-kinh" element={<TuRuouFullKinh />} />
          <Route path="/product/accessory/tu-bep" element={<PhuKienTuBep />} />
          <Route path="/product/accessory/phong-thay-do" element={<PhuKienPhongThayDo />} />
          <Route path="/product/accessory/tu-ruou" element={<PhuKienTuRuou />} />
          <Route path="/product/dressing-room/tu-ao-canh-kinh" element={<TuAoCanhKinh />} />
          <Route path="/product/dressing-room/tu-ao-full-kinh" element={<TuAoFullKinh />} />
          <Route path="/product/kitchen/smart" element={<HeTuBepSmart />} />
          <Route path="/product/kitchen/smart/pro" element={<SmartKitchenPro />} />
          <Route path="/product/kitchen/smart/elegance" element={<SmartSpaceElegance />} />
          <Route path="/product/kitchen/luxury" element={<HeTuBepLuxury />} />
          <Route path="/product/kitchen/luxury/luxury-kitchen-elite" element={<LuxuryKitchenElite />} />
          <Route path="/product/kitchen/luxury/luxury-space-grandeur" element={<LuxurySpaceGrandeur />} />
          <Route path="/project" element={<Project />} />
          <Route path="/project/project1" element={<Project1 />} />
          <Route path="/project/project2" element={<Project2 />} />
          <Route path="/project/project3" element={<Project3 />} />
          <Route path="/project/project4" element={<Project4 />} />
          <Route path="/solution" element={<Solution />} />
          <Route path="/showroom" element={<Showroom />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
